<template>
  <div class="about">
    <el-button size="small" @click="AddCollection()">新增</el-button>

    <el-table
      :data="CollectionDatas.Datas"
      border
      style="width: 100%"
      height="600"
    >
      <el-table-column label="名称" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="合集类型" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{
              scope.row.ProductCollectionTypeFormat
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="价格" width="120">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Price }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="最大数量" width="100">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.MaxCount }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="开始日期" width="200">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.BeginDate }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="结束日期" width="200">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.EndDate }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="500px">
        <template #default="scope">
          <el-button
            type="button"
            size="small"
            @click="EditWindow(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="DeleteProduct(scope.$index, scope.row)"
            >删除</el-button
          >
          <el-button
            size="small"
            @click="CollectionBind(scope.$index, scope.row)"
            >编辑商品</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 修改或者新增合集 -->
  <el-drawer
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    :size="clientWidth - 200"
    :before-close="EditOrAddWindowClose"
    :with-header="false"
  >
    <span class="dialog-footer">
      <el-button @click="EditOrAddWindowClose">关闭</el-button>
      <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
    </span>
    <el-scrollbar :height="clientHeight - 150 + 'px'">
      <el-form ref="form" label-position="right" label-width="100px">
        <el-row gutter="30" class="dialog-manages-rowwidth">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="名称">
              <el-input v-model="CollectionModel.Name"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select
                size="small"
                v-model="CollectionModel.ProductCollectionType"
                class="m-2"
                placeholder="Select"
              >
                <el-option
                  v-for="item in CollectionTypes"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="价格">
              <el-input-number
                v-model="CollectionModel.Price"
                size="small"
                :precision="2"
                :max="9999"
                :min="0.1"
              />
            </el-form-item>
            <el-form-item label="开始日期">
              <!-- <el-input v-model="CollectionModel.BeginDate"></el-input> BeginDate_EndDate -->
              <el-date-picker
                v-model="CollectionModel.BeginDate"     value-format="YYYY/MM/DD HH:mm:ss"
                type="date"
                placeholder="Pick a day"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <!-- <el-input v-model="CollectionModel.BeginDate"></el-input> BeginDate_EndDate -->
              <el-date-picker
                v-model="CollectionModel.EndDate"     value-format="YYYY/MM/DD HH:mm:ss"
                type="date"
                placeholder="Pick a day"
                size="small"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="开始时间">
              <el-select
                v-model="CollectionModel.BeginTime"
                class="m-2"
                placeholder="Select"
                size="small"
              >
                <el-option
                  v-for="item in TimesRange"
                  :key="item"
                  :label="item + ':00'"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-select
                v-model="CollectionModel.EndTime"
                class="m-2"
                placeholder="Select"
                size="small"
              >
                <el-option
                  v-for="item in TimesRange"
                  :key="item"
                  :label="item + ':00'"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="最大购买数量">
              <el-input-number
                v-model="CollectionModel.MaxCount"
                :min="1"
                :max="9999"
                size="small"
              />
            </el-form-item>

            <el-form-item label="甜品数量">
              <el-input-number
                v-model="CollectionModel.Sweetmeats"
                :min="1"
                :max="9999"
                size="small"
              />
            </el-form-item>

            <el-form-item label="饮料数量">
              <el-input-number
                v-model="CollectionModel.Drinks"
                :min="1"
                :max="9999"
                size="small"
              />
            </el-form-item>

            <el-form-item label="食品数量">
              <el-input-number
                v-model="CollectionModel.Foodstuff"
                :min="1"
                :max="9999"
                size="small"
              />
            </el-form-item>

            <el-form-item label="其他数量">
              <el-input-number
                v-model="CollectionModel.Rest"
                :min="1"
                :max="9999"
                @change="handleChange"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="最大购买数量">
              <el-input-number
                v-model="CollectionModel.MaxCount"
                :min="1"
                :max="9999"
                @change="handleChange"
                size="small"
              />
            </el-form-item>
            <el-form-item label="小项免费数量">
              <el-input-number
                v-model="CollectionModel.FreeCount"
                :min="1"
                :max="9999"
                size="small"
              />
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                v-model="CollectionModel.Description"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="封面图片">
              <el-upload
                :action="UploadImageUrl"
                :limit="1"
                list-type="picture-card"
                :auto-upload="true"
                :file-list="fileList"
                :before-upload="UploadImage"
                :on-success="UploadSuccess"
                :headers="headersImage"
              >
                <template #default>
                  <el-icon><plus /></el-icon>
                </template>
                <template #file="{ file }">
                  <div>
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <el-icon><zoom-in /></el-icon>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <el-icon><download /></el-icon>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <el-icon><delete /></el-icon>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload>
              <el-dialog v-model="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
  </el-drawer>

  <!-- 合集绑定管理 -->
  <el-dialog
    v-model="CollectionBindWindow"
    title="绑定商品"
    width="80%"
    :before-close="CollectionBindWindowClose"
  >
    <el-scrollbar height="500px">
      <el-tabs type="card" @tab-click="handleClick">
        <el-tab-pane label="分类">
          <el-row gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-table
                height="400px"
                :data="ClassifyDatas"
                border
                style="width: 100%"
              >
                <el-table-column label="名称" width="180">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scope.row.Name
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="简介" width="180">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scope.row.Detail
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="100px">
                  <template #default="xscope">
                    <el-button
                      v-if="xscope.row.Icon != 'Binding'"
                      type="button"
                      size="small"
                      @click="BindCategory(xscope.$index, xscope.row)"
                      >绑定
                    </el-button>

                    <el-button
                      v-if="xscope.row.Icon == 'Binding'"
                      type="danger"
                      size="small"
                      @click="CancelBindCategory(xscope.$index, xscope.row)"
                      >取消绑定
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-table
                height="400px"
                :data="CollectionModel.CategoryModel"
                border
                style="width: 100%"
              >
                <el-table-column label="名称" width="180">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scope.row.Name
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="简介" width="180">
                  <template #default="scopeCategory">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeCategory.row.Detail
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="300px">
                  <template #default="xscope">
                    <el-button
                      type="danger"
                      size="small"
                      @click="CancelBindCategory(xscope.$index, xscope.row)"
                      >取消绑定</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="商品">
          <el-row gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-table
                height="400px"
                :data="ProductDatas"
                border
                style="width: 100%"
              >
                <el-table-column label="名称" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Name
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Sku" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Sku
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="编号" width="220">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Code
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Price
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="100px">
                  <template #default="scopeProduct">
                    <el-button
                      v-if="scopeProduct.row.Description != 'Binding'"
                      type="button"
                      size="small"
                      @click="
                        BindProduct(scopeProduct.$index, scopeProduct.row)
                      "
                      >绑定</el-button
                    >
                    <el-button
                      v-if="scopeProduct.row.Description == 'Binding'"
                      type="danger"
                      size="small"
                      @click="
                        CancelProductBind(scopeProduct.$index, scopeProduct.row)
                      "
                      >取消绑定</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-table
                height="400px"
                :data="CollectionModel.ProductModel"
                border
                style="width: 100%"
              >
                <el-table-column label="名称" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Name
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Sku" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Sku
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="编号" width="220">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Code
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="180">
                  <template #default="scopeProduct">
                    <div style="display: flex; align-items: center">
                      <el-icon><timer /></el-icon>
                      <span style="margin-left: 10px">{{
                        scopeProduct.row.Price
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="100px">
                  <template #default="scopeProduct">
                    <el-button
                      type="danger"
                      size="small"
                      @click="
                        CancelProductBind(scopeProduct.$index, scopeProduct.row)
                      "
                      >取消绑定</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>

          <el-pagination
            v-model:currentPage="currentPage2"
            :page-sizes="[1, 200, 300, 400]"
            :page-size="Size"
            layout="sizes, prev, pager, next"
            :total="ProductTotal"
            @size-change="GetProductSizeChange"
            @current-change="GetProductCurrentChange"
          >
            <!-- // prev-click	用户点击上一页按钮改变当前页时触发	新当前页
    // next-click	用户点击下一页按钮改变当前页时触发	新当前页 -->
          </el-pagination>
        </el-tab-pane>
      </el-tabs>

      <el-divider></el-divider>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="CollectionBindWindowClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import tools from "../../../commons/tools.js"
import { ElMessageBox, ElSelect } from "element-plus"

import { Plus, ZoomIn, Download, Delete } from "@element-plus/icons-vue" 
export default {
  data () {
    return {
      IsAdd: false,
      EditOrAddWindow: false,
      CollectionDatas: [],
      CollectionModel: {
        Price: 0
      },
      imageUrl: null,
      fileList: [],
      UploadImageUrl: "",
      headersImage: "",
      dialogImageUrl: "",
      dialogVisible: false,
      CategoryOpts: [], // 选中分类
      ClassifyDatas: [], // 分类集合
      SelectCollectionOpts: [], // 筛选条件
      TimesRange: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23
      ],
      OptionsTime: [],
      CollectionTypes: [],
      CollectionBindWindow: false,

      ProductDatas: [], // 分类集合

      Size: 50, // 页大小
      Page: 1, // 页码
      ProductTotal: 0, // 商品总数量

      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight
    }
  },
  name: "App",
  components: {
    Plus,
    ZoomIn,
    Download,
    Delete
  },
  mounted () {
    this.GetCollections()
    this.GetCollectionTypes()
    this.GetCategory()
    this.GetProducts()

    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }
  },
  methods: {
    // 取消绑定商品
    CancelProductBind (index, item) {
      ElMessageBox.confirm("确定要取消绑定吗?")
        .then(() => {
          axios.apiMethod(
            "/api/Collection/CancelBindyProduct?Id=" +
              item.Id +
              "&CollectionId=" +
              this.CollectionModel.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                // this.ProductDatas[index].Description = "";
                this.GetCollections()
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },

    // 绑定商品
    BindProduct (index, item) {
      var param = {
        CollectionId: this.CollectionModel.Id,
        Id: item.Id
      }
      axios.apiMethod(
        "/api/Collection/CollectionBindCategoryByProductId",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.GetCollections()

            // this.ProductDatas[index].Description = "Binding";
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },

    //	pageSize 改变时触发
    GetProductSizeChange (pageSize) {
      this.Size = pageSize
      this.GetProducts()
    },
    // current-change 改变时触发
    GetProductCurrentChange (Page) {
      this.Page = Page
      this.GetProducts()
    },
    // 查询分类集合
    GetProducts () {
      var param = {
        Page: this.Page,
        Size: this.Size,
        Where: { CategoryOptIds: this.SelectCategoryOpts }
      }
      axios.apiMethod("/api/Products/GetProducts", "post", param, (result) => {
        if (result.Code == 200) {
          this.ProductDatas = result.Data.Datas
          this.ProductTotal = result.Data.Count
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 取消绑定分类
    CancelBindCategory (index, item) {
      ElMessageBox.confirm("确定要取消绑定吗?")
        .then(() => {
          axios.apiMethod(
            "/api/Collection/CancelBindCategory?Id=" +
              item.Id +
              "&CollectionId=" +
              this.CollectionModel.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                this.GetCollections()
                // this.ClassifyDatas[index].Description = "";
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    // 绑定分类
    BindCategory (index, item) {
      var param = {
        CollectionId: this.CollectionModel.Id,
        Id: item.Id
      }
      axios.apiMethod(
        "/api/Collection/CollectionBindProductByCategoryId",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.GetCollections()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },

    // 打开绑定页面
    CollectionBind (index, item) {
      this.CollectionBindWindow = true
      this.CollectionModel = item
    },
    CollectionBindWindowClose () {
      this.CollectionBindWindow = false
    },
    ArrangeCollectionModel () {
      this.ProductDatas.forEach((e) => {
        e.Description = ""
      })

      for (var j = 0; j < this.ProductDatas.length; j++) {
        const product = this.ProductDatas[j]
        this.CollectionModel.ProductModel.forEach((e) => {
          if (product.Id == e.Id) {
            product.Description = "Binding"
          }
          this.ProductDatas[j] = product
        })
      }

      this.ClassifyDatas.forEach((e) => {
        e.Icon = ""
      })

      for (var i = 0; i < this.ClassifyDatas.length; i++) {
        var Classify = this.ClassifyDatas[i]
        this.CollectionModel.CategoryModel.forEach((e) => {
          if (Classify.Id == e.Id) {
            Classify.Icon = "Binding"
          }
          this.ClassifyDatas[i] = Classify
        })
      }
    },
    // 查询合集类别
    GetCollectionTypes () {
      var param = { Size: 2000 }
      axios.apiMethod(
        "/api/Collection/GetCollectionTypes",
        "get",
        param,
        (result) => {
          if (result.Code == 200) {
            this.CollectionTypes = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 获取分类列表
    GetCategory () {
      var param = { Size: 2000 }
      axios.apiMethod(
        "/api/Category/GetCategoryAll",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.ClassifyDatas = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传成功操作
    UploadSuccess (response, file, fileList) {
      if (response.Code == 200) {
        this.CollectionModel.Images = response.Data.FileName
      }
    },
    // 上传文件前操作
    UploadImage (file) {
      const ispng = file.type === "image/png"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!ispng) {
        ElMessage.error("请上传png格式文件")
        return false
      }

      if (!isLt2M) {
        ElMessage.error("请上传2M以内文件")
        return false
      }
    },
    handleRemove (file) {
      this.fileList = []
      this.CollectionModel.Images = ""
    },

    EditOrAddWindowClose () {
      this.EditOrAddWindow = false
    },
    // 打开编辑页面
    EditWindow (index, item) {
      this.EditOrAddWindow = true
      this.CollectionModel = item

      this.CategoryOpts = []

      this.fileList = []
      if (
        this.CollectionModel.Images != "" &&
        this.CollectionModel.Images != null
      ) {
        this.fileList.push({ url: this.CollectionModel.CompleteImages })
      }
    },
    DeleteProduct (index, item) {
      tools.confirmDelete(item.Name, () => {
        axios.apiMethod(
          "/api/Collection/DeleteCollection?Id=" + item.Id,
          "post",
          {},
          (result) => {
            if (result.Code == 200) {
              this.EditOrAddWindow = false
              this.GetCollections()
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error"
              })
            }
          }
        )
      })

      // ElMessageBox.confirm("确定要删除" + item.Name + "吗？")
      //   .then()
      //   .catch(() => {
      //     // catch error
      //   });
    },
    AddCollection () {
      this.CollectionModel = { Price: 0, MaxCount: 9999 }
      this.EditOrAddWindow = true
      this.IsAdd = true
    },
    // 加载合集列表
    GetCollections () {
      var param = { Where: { CategoryOptIds: this.SelectCategoryOpts }}
      axios.apiMethod(
        "/api/Collection/GetCollections",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.CollectionDatas = result.Data
            this.CollectionDatas.Datas.forEach((e) => {
              if (this.CollectionModel.Id == e.Id) {
                this.CollectionModel = e
                this.ArrangeCollectionModel()
              }
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 保存或者修改
    EditOrAddSave () {
      this.CollectionTypes.forEach((e) => {
        if (e.Key == this.CollectionModel.ProductCollectionType) {
          this.CollectionModel.ProductCollectionTypeId = e.Key
        }
      })

      axios.apiMethod(
        "/api/Collection/AddOrEditCollection",
        "post",
        this.CollectionModel,
        (result) => {
          if (result.Code == 200) {
            this.GetCollections()
            this.EditOrAddWindowClose()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.manage_title {
  height: 50px;
  background-color: rgb(67, 74, 80);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.dialog-manages-height {
  height: 400px;
  overflow: auto;
  width: 95%;
  padding: 10px;
}

.dialog-manages-rowwidth {
  width: 95% !important;
}
</style>
